.container {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  height: 80%;
}

.close {
  position: absolute;
  right: 22px;
  top: 34px;
  padding: 18px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.desc {
  margin-top: 16px;
  font-size: 20px;
  color: #333333;
}
.h5desc {
  margin-top: 16px;
  font-size: 16px;
  color: #8c8c8c;
}

.gapTop36 {
  margin-top: 36px;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
}

.btn {
  flex: 1;
  height: 52px;
  border-radius: 12px;
}

.editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: #f7f8fa;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 16px;

  :global {
    .w-e-text-container {
      background-color: #f7f8fa;
    }
    .w-e-toolbar {
      background-color: #f7f8fa;
    }
  }
}
