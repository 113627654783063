.container {
  display: flex;
  flex-direction: column;
}

.time {
  color: #595959;
  font-size: 16px;
}

.caption {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  min-height: 24px;
  margin-bottom: 32px;
}
.h5time {
  color: #595959;
  font-size: 12px;
}

.h5caption {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  min-height: 24px;
  margin-bottom: 16px;
}
