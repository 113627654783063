.tab {
  height: 44px;
  background-color: #f7f8fa;
  padding: 4px;
  border-radius: 31px;
}

.tabItem {
  height: 36px;
  font-size: 16px;
  min-width: 140px;
  border-radius: 31px;
  color: #595959;
  padding: 0px 16px;
}

.tabItemActive {
  background-color: white;
  color: #333333;
}
