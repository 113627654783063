.layout {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  color: #333;
  flex: 1;
  width: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}
.headerText {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}
.body {
  background-color: #f7f8fa;
  border-radius: 24px;
  padding: 24px;
}

.summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  color: #000;
}

.leftText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.number {
  font-size: 55px;
  font-weight: bold;
  line-height: 60px;
  margin: 8px 0;
}

.percent {
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.time {
  font-size: 14px;
  color: #8c8c8c;
  margin-left: 8px;
}
.table {
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  margin-top: 24px;
}
.tableTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 24px;
}
.virtualScroller {
  min-height: 200px;
}
.columnHeader {
  &:focus {
    outline: none !important;
  }
  &:hover {
    background-color: #f7f8fa !important;
  }
}
.cell {
  color: #595959;
  &:focus {
    outline: none !important;
  }
}
.row {
  &:hover {
    background-color: #f7f8fa !important;
  }
}
.columnSeparator {
  display: none !important;
}
