.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.h5UpdatePaper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 24px;
  color: #333;
}

.inputSearch {
  height: 44px;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.btn {
  height: 36px;
  border-radius: 6px;
  margin-top: 36px;
}

.btnMobile {
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  height: 240px;
  padding-bottom: 20px;
  overflow-y: scroll;
}

.emptyContent {
  justify-content: center;
  align-items: center;
}

.emptyText {
  font-size: 16px;
  font-weight: bold;
}

.item {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.itemText {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}
