.container {
  min-width: 690px;
  overflow-y: scroll;
  height: 100vh;
  display: flex;
  padding: 0 40px;
  background-color: #f7f8fa;
  flex-direction: column;
  flex: 1;
}

.welcome {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.welcomeText {
  color: #333333;
  font-weight: bold;
  font-size: 32px;
  margin-top: 40px;
}

.welcomeTip {
  font-size: 20px;
  margin-top: 16px;
  color: #8c8c8c;
}

.logoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: 140px;
  height: 140px;
  background-color: white;
  border-radius: 24px;
}

.logo {
  width: 100px;
  height: 100px;
}

.text {
  font-size: 20px;
  color: #8c8c8c;
  line-height: 30px;
}

.start {
  min-width: 260px;
  background-color: #3333f5;
  height: 52px;
  color: #fff;
  font-size: 18px;
  border-radius: 12px;
  margin-top: 56px;
  margin-bottom: 58px;
}

.startNew {
  height: 52px;
  font-size: 18px;
  border-radius: 12px;
  margin-right: 40px;
}

.copy {
  min-width: 260px;
  height: 52px;
  font-size: 18px;
  border-radius: 12px;
}

.header {
  display: flex;
  padding-bottom: 38px;
  padding-top: 48px;
  flex-direction: row;
  align-items: center;
}

.content {
  flex: 1;
  border-radius: 24px;
  border: 36px solid #f7f8fa;
  background-color: #f7f8fa;
  min-height: 300px;
  overflow-y: scroll;
}

.bottomBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  margin-bottom: 58px;
}
.feedback {
  .startNew();
  align-self: center;
  background-color: #fff5db;
  color: #333;
  width: 184px;
  box-shadow: none;
  &:hover {
    background-color: #fff5db;
  }
}
.feedbackImg {
  height: 133px;
  margin-left: 35px;
}
@keyframes fadeOut {
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}
.tooltip {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0 !important;
  animation: fadeOut 6.28s forwards;
}
