.item {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.margin {
  margin-top: 24px;
}

.label {
  color: #8c8c8c;
}
