.layout {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  color: #333;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.chip {
  color: #5a5af6;
  background-color: #ececff;
  border-radius: 6px;
  padding: 4px 8px;
}

.cellDiseases {
  flex-wrap: wrap;
  gap: 4px;
  &:hover {
    background-color: #fff;
    margin: 2px 0;
    border-radius: 4px;
  }
  &:hover .edit {
    visibility: visible;
  }
}

.pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.headerText {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}

.body {
  background-color: #f7f8fa;
  border-radius: 24px;
  padding: 24px;
}

.summary {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  overflow-x: auto;
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  width: 358px;
}

.leftText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.number {
  font-size: 55px;
  font-weight: bold;
  line-height: 60px;
  margin: 8px 0;
}

.percent {
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.time {
  font-size: 14px;
  color: #8c8c8c;
  margin-left: 8px;
}

.table {
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  margin-bottom: 24px;
}

.tableTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}

.main {
  border: 1px solid #e5e5f5;
  border-radius: 12px;
  overflow-x: scroll;
}

.virtualScroller {
  min-height: 200px;
}

.columnHeader {
  border-bottom: 1px solid #e5e5f5 !important;

  &:focus {
    outline: none !important;
  }

  &:hover {
    background-color: #f7f8fa !important;
  }
}

.cell {
  color: #595959;

  &:focus {
    outline: none !important;
  }
}

.row {
  &:hover {
    background-color: #f7f8fa !important;
  }
}

.columnSeparator {
  display: none !important;
}

.inputRoot {
  height: 44px;
  padding: 0 8px !important;
  background-color: #fff !important;
}
.rootClass {
  width: 200px;
  height: 44px;
}
.chart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 24px;
}
.chartItem {
  background-color: #fff;
  border-radius: 24px;
  padding: 24px;
}
.durationItem {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 0 8px;
  cursor: pointer;
}
.durationPercent {
  width: 74px;
  font-size: 14px;
  text-align: center;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.diseaseName {
  color: #595959;
  width: 100px;
  overflow-x: auto;
  white-space: nowrap;
  text-decoration: underline;
}
.diseaseWrap {
  flex: 1;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.edit {
  width: 24px;
  height: 24px;
  visibility: hidden;
}
