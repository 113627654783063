.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter {
  font-weight: normal;
  color: #466cb5;
}

.svg {
  margin-right: 8px;
  color: #466cb5;
}

.hoverPaper {
  padding: 8px 0;
  margin: 8px 0 0;
  border: 1px solid rgba(51, 51, 245, 0.08);
  box-shadow: 8px 8px 16px rgba(170, 170, 170, 0.4);
  border-radius: 12px;
  z-index: 2;
}
.popoverItemLabel {
  font-size: 16px;
  color: #333;
  padding: 8px 16px;
  cursor: pointer;
  min-width: 100px;
  &:hover {
    background-color: #f7f8fa;
  }
}

.clearItem {
  // margin-left: 8px;
  height: 44px;
  color: #333;
  border-radius: 8px;
  font-size: 12px;
  text-transform: none;
  padding: 0 16px;
  background-color: #f7f8fa;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 16px;
}

.clearContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
  flex: 1;
  margin-right: 16px;
}
.searchInput {
  margin: 0 12px 8px;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.searchInputRoot {
  padding: 8px 12px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1301;
  border: 1px solid red;
}
