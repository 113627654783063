.container {
  display: flex;
  flex-direction: column;
}
.emptyContainer {
  height: 100%;
  align-items: center;
}
.emptyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}
.wrap {
  display: flex;
  align-items: center;
  background-color: #3333f5;
  border-radius: 12px;
  height: 92px;
  padding: 0 24px;
}
.h5wrap {
  height: auto;
  padding: 12px;
}

.noteTip {
  flex: 1;
  font-weight: 500;
  color: white;
  padding-right: 12px;
}

.regenerate {
  background-color: white;
  border-radius: 12px;
  height: 52px;
  padding: 0 24px;
  &:hover {
    background-color: white;
    opacity: 0.8;
  }
}
.h5regenerate {
  width: 24px;
  height: 24px;
  padding: 4px;
  min-width: 0;
  border-radius: 4px;
}

.refresh {
  margin-left: 12px;
}
.h5refresh {
  margin-left: 0;
  width: 16px;
  height: 16px;
}
@keyframes rotoImg {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.refreshLoading {
  animation: rotoImg 1s linear infinite;
}
.text {
  color: #333333;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.h5text {
  font-size: 14px;
  line-height: 24px;
}

.emptyIcon {
  margin-top: 25px;
  width: 240px;
  height: 240px;
}
.h5EmptyIcon {
  margin-top: 20%;
  width: 100px;
  height: 100px;
}
.emptyTitle {
  text-align: center;
  margin-top: 36px;
  font-size: 32px;
  font-weight: bold;
}
.h5EmptyTitle {
  font-size: 24px;
  margin-top: 16px;
}
.emptyDesc {
  text-align: center;
  margin-top: 16px;
  font-size: 20px;
  color: #8c8c8c;
}
.h5EmptyDesc {
  margin-top: 8px;
  font-size: 14px;
}
