.container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
}

.close {
  position: absolute;
  right: 22px;
  top: 34px;
  padding: 18px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
}

.desc {
  margin-top: 16px;
  font-size: 20px;
  color: #333333;
}

.noteTemplate {
  margin-top: 36px;
  margin-bottom: 8px;
  color: #8c8c8c;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 48px;
}

.btn {
  width: 240px;
  height: 52px;
  border-radius: 12px;
}

.mfaDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  background: #f7f8fa;
  border-radius: 12px;
}
.mfaDescText {
  font-size: 16px;
  color: #333333;
  margin: 0 0 0 16px;
  padding: 0 8px;
}
.qr {
  width: 160px;
  height: 160px;
  margin-left: 16px;
}
