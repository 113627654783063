.container {
  display: flex;
  flex-direction: column;
  width: 800px;
  align-items: center;
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
}
.title {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 24px;
  text-align: center;
  color: #333;
}
.body {
  flex: 1;
  overflow-y: auto;
  background-color: #f7f8fa;
  padding: 24px;
  border-radius: 24px;
}

.btn {
  width: 280px;
  height: 52px;
  border-radius: 12px;
  margin-top: 24px;
}
