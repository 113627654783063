.root {
  width: 223px;
  height: 52px;
}
.input {
  color: #333;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  padding-right: 9px !important;
  background-color: #f5f5f5;
}
