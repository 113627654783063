.layout {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  color: #333;
  flex: 1;
  width: 0;
  box-sizing: border-box;
  padding-bottom: 120px;
  overflow-y: auto;
}

.setting {
  display: flex;
  text-align: start;
  align-items: center;
  justify-content: start;
  font-size: 32px;
  color: #333333;
  font-weight: bold;
  padding-bottom: 32px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(51, 51, 245, 0.08);
  text-decoration: none;
}

.item {
  display: flex;
  align-items: center;
}

.itemRight {
  display: flex;
  align-items: center;
  width: 420px;
}

.margin {
  margin-top: 24px;
}

.label {
  width: 380px;
  padding-right: 80px;
  font-weight: bold;
}

.textField {
  flex: 1;
}

.logout {
  border-radius: 12px;
  margin-left: 12px;
  width: 97px;
  height: 52px;
}

.save {
  margin-top: 100px;
  height: 52px;
  align-self: center;
  width: 280px;
}
