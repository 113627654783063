.container {
  width: 500px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.header {
  display: flex;
  font-size: 24px;
  color: #333;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.logo {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.icon {
  display: block;
  margin: 0 auto 16px;
}

.text {
  font-size: 20px;
  color: #8c8c8c;
  line-height: 30px;
  text-align: center;
}
.button {
  width: 260px;
  background-color: #3333f5;
  padding: 12px 0;
  color: #fff;
  font-size: 18px;
  margin: 56px 0 24px;
  border-radius: 12px;
}
.signInRow {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 20px;
}
.signIn {
  color: #3333f5;
  text-decoration: underline;
  cursor: pointer;
}
.stepContainer {
  margin: 5% auto 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stepBox {
  display: flex;
  margin-top: 30px;
  margin-bottom: 100px;
}
.step {
  width: 103px;
  height: 8px;
  border-radius: 12px;
  background-color: #3333f5;
  opacity: 8%;
  margin-right: 12px;
}
.card {
  width: 540px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}
.cardTitle {
  font-size: 32px;
  line-height: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 12px;
  text-align: center;
}
.label {
  font-size: 16px;
  color: #8c8c8c;
}
.input {
  margin-bottom: 36px;
}
.inputRoot {
  border-radius: 12px;
  background-color: #f5f5f5;
}
.checkRow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #8c8c8c;
}
.disabled {
  opacity: 0.2;
  color: #fff !important;
  background-color: #3333f5 !important;
}
.next {
  display: block;
  margin: 24px auto 0;
}
