.container {
  display: flex;
  flex-direction: column;
  width: min(500px, 90%);
  align-items: center;
  padding: 100px 40px 70px;
  background-color: #ffffff;
  border-radius: 12px;
}

.close {
  position: absolute;
  right: 22px;
  top: 20px;
  padding: 18px;
}

.logo {
  width: 100px;
  height: 100px;
}

.userName {
  font-size: 20px;
  color: #8c8c8c;
  margin-top: 24px;
}

.btn {
  width: min(280px, 90%);
  height: 52px;
  border-radius: 12px;
  margin-top: 56px;
}
