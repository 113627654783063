.container {
  height: 806px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
}

.pdfHeader {
  position: absolute;
  right: 0;
  top: 0;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 14px;
  color: #333;
  font-weight: bold;
}

.link {
  color: #333;
  font-size: 10px;
}

.logo {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.titleScribe {
  white-space: pre-line;
  margin-top: 38px;
  color: #333;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
}

.titleScribeDes {
  margin-top: 16px;
  color: #333;
  font-size: 14px;
  line-height: 24px;
}

.chooseContainer {
  color: white;
  padding: 0 8px;
  margin-top: 36px;
  margin-bottom: 20px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  background-color: #3333f5;
}

.choose {
  text-transform: uppercase;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
}

.whyDes {
  font-size: 15px;
  line-height: 24px;
  position: relative;
  padding-left: 24px;

  &:before {
    z-index: 1;
    content: ' ';
    position: absolute;
    left: 9px;
    top: 9px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #333;
  }
}

.bottom {
  display: flex;
  padding: 20px;
  margin-top: 8px;
  background-color: #3b7de5;
}

.bottomLeft {
  flex: 1;
  color: white;
}

.changeWrap {
  display: flex;
  align-items: center;
}

.change {
  white-space: pre-line;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.qrText {
  font-size: 13px;
  margin-top: 8px;
}

.qr {
  width: 130px;
  height: 130px;
  border-radius: 8px;
}

.contact {
  color: white;
  background-color: #4d8df2;
  padding: 8px;
  height: 42px;
  font-size: 10px;
  white-space: pre-line;
  text-align: center;
}

.hand {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}
