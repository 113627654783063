.container {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
}
.h5container {
  padding: 32px 24px 16px;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
}

.close {
  position: absolute;
  right: 22px;
  top: 34px;
  padding: 18px;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
}

.desc {
  margin-top: 16px;
  font-size: 20px;
  color: #333333;
}
.h5desc {
  margin-top: 16px;
  font-size: 16px;
  color: #8c8c8c;
}

.gapTop36 {
  margin-top: 36px;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn {
  flex: 1;
  height: 52px;
  border-radius: 12px;
  margin-top: 56px;
}
