.rootContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.editor {
  flex: 1;
  overflow-y: auto;
}
