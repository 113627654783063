.container {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
}

.close {
  position: absolute;
  right: 22px;
  top: 40px;
  height: 40px;
  width: 40px;
}

.title {
  height: 40px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
}

.desc {
  margin-top: 36px;
  margin-bottom: 8px;
  color: #8c8c8c;
}

.textInput {
  height: 200px !important;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn {
  flex: 1;
  height: 52px;
  border-radius: 12px;
  margin-top: 48px;
}

.h5container {
  padding: 32px 24px 16px;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
}
