.container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}
.iframe {
  border: none;
}
.h5container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 12px;
}
.title {
  font-size: 24px;
  color: #333;
}
.agreement {
  font-size: 14px;
  white-space: pre-line;
  color: #8c8c8c;
}
.agreement a {
  padding-left: 4px;
  color: #3333f5;
}
.h5UpdatePaper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
}
.close {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 18px;
}
