.layout {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  color: #333;
  flex: 1;
  width: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.headerText {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}

.body {
  min-width: 1000px;
  background-color: #f7f8fa;
  border-radius: 24px;
  padding: 24px;
}

.table {
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
}

.dataGrid {
  border: 1px solid #e5e5f5;
  border-radius: 12px;
  overflow-x: scroll;
}

.columnHeader {
  border-bottom: 1px solid #e5e5f5 !important;

  &:focus {
    outline: none !important;
  }

  &:hover {
    background-color: #f7f8fa !important;
  }
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 16px;
}

.tableTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  flex: 1;
}

.row {
  &:hover {
    background-color: #f7f8fa !important;
    cursor: pointer;
  }
}

.add {
  margin-left: 16px;
  font-size: 18px;
  font-weight: 600;
  height: 44px;
  border-radius: 8px;
}

.pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}

.link {
  width: 180px;
  white-space: nowrap;
  overflow-x: scroll;
  color: #3333f5;
  text-decoration: underline;
}

.editBtn {
  padding: 8px 16px;
}

.editIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
