.layout {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  color: #333;
  flex: 1;
  width: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}
.headerText {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}
.body {
  background-color: #f7f8fa;
  border-radius: 24px;
  padding: 24px;
}
.table {
  padding: 24px;
  background-color: #fff;
  border-radius: 24px;
  margin-top: 24px;
  overflow-x: auto;
}
.filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 16px;
}
.tableTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  flex: 1;
}
.virtualScroller {
  min-height: 200px;
}
.columnHeader {
  &:focus,
  &:focus-within {
    outline: none !important;
  }
  &:hover {
    background-color: #f7f8fa !important;
  }
}
.cell {
  color: #595959;
  padding: 10px !important;
  &:focus,
  &:focus-within {
    outline: none !important;
  }
}
.row {
  &:hover {
    background-color: #f7f8fa !important;
    cursor: pointer;
  }
}
.columnSeparator {
  display: none !important;
}

.inputRoot {
  width: 160px;
  height: 52px;
  padding: 0 8px !important;
}
.export {
  margin-left: 16px;
  font-size: 16px;
  font-size: 700;
  width: 125px;
  height: 52px;
  border-radius: 8px;
}
.chip {
  color: #5a5af6;
  background-color: #ececff;
  border-radius: 6px;
  padding: 4px 8px;
}
.cellDiseases {
  flex-wrap: wrap;
  gap: 4px;
}
.detailTitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  background-color: #f7f8fa;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 36px;
}
.pagination {
  flex: 1;
  display: flex;
  justify-content: center;
}
