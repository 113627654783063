.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerText {
  font-size: 18px;
  font-weight: bold;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding-left: 16px;

  &:before {
    z-index: 1;
    content: ' ';
    position: absolute;
    left: 0;
    top: 13px;
    border-radius: 3px;
    width: 6px;
    height: 6px;
    background: black;
  }
}

.itemName {
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
}

.itemNameMobile {
  flex: 1;
  line-height: 20px;
  font-size: 12px;
}

.buttonDelete {
  margin-left: 12px;
  padding: 0;
}

.itemIcon {
  margin-top: 4px;
  width: 24px;
  height: 24px;
}

.itemIconMobile {
  margin-top: 0px;
  width: 20px;
  height: 20px;
}
