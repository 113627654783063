.layout {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
}

.left {
  width: 324px;
  height: 100%;
  background-color: #f7f8fa;
  padding: 58px 16px 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 100%;
  margin-bottom: 52px;
}

.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.menuItem {
  display: flex;
  text-align: start;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  color: #595959;
  padding: 16px 16px 16px 20px;
  margin-bottom: 12px;
  text-decoration: none;
}

.active {
  color: #333;
  font-weight: bold;
  background-color: #fff;
  border-radius: 16px;
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.safety {
  padding: 12px 16px;
  background: #e3f0e1;
  color: #333;
  font-size: 18px;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.hipaa {
  padding: 12px 16px;
  background: #fff;
  color: #333;
  font-size: 14px;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

:global {
  .MuiDataGrid-root {
    .MuiDataGrid-virtualScroller {
      min-height: 200px;
    }
    .MuiDataGrid-columnHeader {
      &:focus,
      &:focus-within {
        outline: none;
      }
      &:hover {
        background-color: #f7f8fa;
      }
    }
    .MuiDataGrid-cell {
      color: #595959;
      padding: 10px;
      &:focus,
      &:focus-within {
        outline: none;
      }
    }
    .MuiDataGrid-row {
      &:hover {
        background-color: #f7f8fa;
        cursor: pointer;
      }
      &.Mui-selected {
        background-color: #f7f8fa;
        &:hover,
        &.Mui-hovered {
          background-color: #f7f8fa;
        }
      }
    }
    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }
}
