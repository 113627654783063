.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(51, 51, 245, 0.08);
}

.noDivider {
  border-bottom: none;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  line-height: 30px;
  padding: 32px 0;
}
