.container {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  font-family: 'Roboto';
}

.close {
  position: absolute;
  right: 22px;
  top: 32px;
  height: 40px;
  width: 40px;
}

.title {
  height: 40px;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
}

.desc {
  margin-top: 36px;
  margin-bottom: 8px;
  color: #8c8c8c;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn {
  flex: 1;
  height: 52px;
  border-radius: 12px;
  margin-top: 48px;
}

.h5container {
  padding: 32px 24px 16px;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 16px 20px;
  gap: 12px;
  background: #f7f8fa;
  border: 1px solid rgba(51, 51, 245, 0.08);
  border-radius: 12px;
  margin: 36px 0 0;
  min-height: 200px;
  max-height: 50vh;
  overflow-y: auto;
}
.tagItem {
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;
  background-color: #ececff;
  border-radius: 4px;
}
.textInput {
  color: #5a5af6;
  font-size: 16px;
  padding: 8px 12px;
  background-color: #ececff;
  min-width: 50px;
  &:focus {
    outline: none;
  }
  &:empty::before {
    content: 'Place Enter';
    color: #8c8c8c;
    padding-left: 4px;
  }
}
.tagComTitle {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin: 32px 0 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.tagComItem {
  padding: 8px 16px;
  background-color: #ececff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #5a5af6;
}
