.titleContainer {
  flex: 1;
  display: flex;
  cursor: pointer;
}

.titleText {
  font-family: Roboto, serif;
  color: #333333;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  word-break: break-word;
}

.title {
  flex: 1;
  font-family: Roboto, serif;
  color: #333333;
  font-size: 32px;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
  border: none;
  padding: 0 12px;
  border-radius: 4px;
  background-color: #f7f8fa;

  &:hover {
    border-radius: 4px;
  }

  &:focus {
    outline: none;
  }
}

.edit {
  margin: 0 20px;
}
