.textField {
}

.root {
  border-radius: 12px;
  background-color: #f5f5f5;
  height: 52px;
}

.label {
  &[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
  }
  line-height: 1.2;
}

.shrink {
  transform: translate(0, 1.5px) scale(0.75);
}

.multiline {
  min-height: 110px;
  align-items: flex-start;
}

.notchedOutline {
  border-radius: 12px;
}
